/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";

import {BlogSCH, getBlogSCH} from "../../search/BlogSCH"
import {EventBus} from '../../helpers/event-bus'
import {Blog} from '../../models/blog'

const url = '/blog'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  blog: new Blog(),
}

const getters = {
  listaAllBlogs: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },

  listaBlogs: (state) => {
    return state.all
  },

  pegaBlog: (state) => {
    return state.blog
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListBlog({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('BLOGS_ALL_CONCLUIDO', true)
  },

  async searchItem({commit}, search) {
    try {
      var filtro = getBlogSCH('blog_id', search, '=')
      const list = (await pegaLista(url, filtro)).data
      commit('SET_BLOG', list.data[0])
      EventBus.$emit('GET_SEARCH_CONCLUIDO', true, list.data[0])
    } catch (error) {
      EventBus.$emit('GET_SEARCH_CONCLUIDO', false,[])
    }
  },
  async getItem({commit}, {id,onSucesso,onErro}) {
    try {
      var filtro = getBlogSCH('blog_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      commit('SET_BLOG', list.data[0])
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BlogSCH(perPage))
  },
  setBlog({commit}, blog) {
    commit('SET_BLOG', blog)
  },
  // Pag
  async filtarBlog({commit}, filtro) {
    const list2 = (await filtrando(url, filtro)).data
    commit('SET_BLOGS', list2)
  },

  async setAllBlogs({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_BLOGS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async setAllBlogsDesbloqueada({commit}) {
    const list = (await lista(url + '/desbloqueado')).data
    commit('SET_BlogS', list)
  },
  async processarBlog({commit}, {blog, acao}) {
    try {
      console.log(blog)
      let res = await processar(url, blog, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('blog/setAllBlogs')
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Blog')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'BlogCadastrar')
    }
  }
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_BLOGS(state, blogs) {
    state.all = blogs
  },
  SET_BLOG(state, blog) {
    state.blog = blog
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
