/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";

import {DoencaVascularSCH, DoencaVenosaSCH, getDoencaSCH, DoencaSCH} from "../../search/DoencaSCH"
import {EventBus} from '../../helpers/event-bus'
import {Doenca} from '../../models/doenca'
import DoencaVascular from "../../views/Doencas/tabs/DoencaVascular";
import DoencaVenosa from "../../views/Doencas/tabs/DoencaVenosa";
import {getBlogSCH} from '../../search/BlogSCH'

const url = '/doenca'

const state = {
  all: [],
  all_vascualres: [],
  all_venosa: [],
  allLista: [],
  filtro: new Filtro(),
  filtro2: new Filtro(),
  doenca: new Doenca(),
}

const getters = {
  listaAllDoencas: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },

  listaDoencas: (state) => {
    return state.all
  },

  listaDoencasVascualres: (state) => {
    return state.all_vascualres
  },
  listaDoencasVenosas: (state) => {
    return state.all_venosa
  },

  pegaDoenca: (state) => {
    return state.doenca
  },
  pegaFiltro: (state) => {
    return state.filtro
  },

}

const actions = {
  async setAllListDoenca({ commit }) {
    const list = (await lista(url + '/all')).data
    // console.log(list)
    commit('SET_ALL_LIST', list)
    EventBus.$emit('DOENCAS_ALL_CONCLUIDO', true)
  },

  async searchItem({commit}, search) {
    try {
      var filtro = getDoencaSCH('doenca_id', search, '=')
      const list = (await pegaLista(url, filtro)).data
      commit('SET_DOENCA', list.data[0])
      EventBus.$emit('GET_SEARCH_CONCLUIDO', true, list.data[0])
    } catch (error) {
      EventBus.$emit('GET_SEARCH_CONCLUIDO', false,[])
    }
  },
  async getItem({commit}, {id,onSucesso,onErro}) {
    try {
      var filtro = getBlogSCH('doenca_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      commit('SET_DOENCA', list.data[0])
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  async getItemDoenca({commit}, id) {
     var filtro = getDoencaSCH('doenca_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
     commit('SET_DOENCA', list.data[0])
     EventBus.$emit('GET_DOENCA_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', DoencaSCH(perPage))
  },

  setDoenca({commit}, doenca) {
     commit('SET_DOENCA', doenca)
  },

  async setAllDoencas({commit}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      // console.log('setAllDoencas: ', list.data)
      commit('SET_DOENCAS', list)

      EventBus.$emit('LISTA_DOENCA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_DOENCA_PROCESSADA', false)
    }
  },

  async setAllDoencasVascular({commit},{onSucesso,onErro}) {
    try {
      var filtro = DoencaVascularSCH('*')

      const list = (await pegaDados(url+'/get', filtro)).data
      // console.log('Vascular: ', list.data)
      commit('SET_DOENCAS_VASCULARES', list.data)
      onSucesso()

      // EventBus.$emit('LISTA_VASCULAR_PROCESSADA', true)
    } catch (error) {
      onErro()
      // EventBus.$emit('LISTA_VASCULAR_PROCESSADA', false)
    }
  },

  async setAllDoencasVenosa({commit}) {
    try {
      var filtro = DoencaVenosaSCH('*')
      const list = (await pegaLista(url, filtro)).data
      // console.log('Venosa: ',list.data)
      commit('SET_DOENCAS_VENOSAS', list.data)
      EventBus.$emit('LISTA_VENOSA_PROCESSADA', true, list.data)
    } catch (error) {
      EventBus.$emit('LISTA_VENOSA_PROCESSADA', false, [])
    }
  },



  async setAllDoencasList({commit}) {
    const list = (await lista(url + '/all')).data
    commit('SET_DOENCAS', list)
  },
  async processarDoenca({commit}, {doenca, acao}) {
    try {
      let res = await processar(url, doenca, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('doenca/setAllDoencas')
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Doenca')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'DoencaCadastrar')
    }
  }
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_DOENCAS(state, doencas) {
      state.all = doencas
  },

  SET_DOENCAS_VENOSAS(state, doencas) {
      state.all_venosas = doencas
  },

  SET_DOENCAS_VASCULARES(state, doencas) {
      state.all_vascualres = doencas
  },


  SET_DOENCA(state, doenca) {
      state.doenca = doenca
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_FILTRO2(state, filtro) {
    state.filtro2 = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
