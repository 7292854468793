/* eslint-disable */
import {lista, processar, pegaLista,listaInstagram} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {InstagramSCH, getBaseSCH} from "../../search/InstagramSCH"
import {EventBus} from '../../helpers/event-bus'
import _ from 'lodash'
import {API} from '../../api'

const url = '/instagram'

const state = {
  all: [],
}

const getters = {
  listaInstagram: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', InstagramSCH(perPage))
  },

  async setAllInstagram({commit}) {
    try {
      const list = (await pegaLista(url, state.filtro)).data
      // console.log('SET_INSTAGRAM: ', list.data)
      commit('SET_INSTAGRAM', list)
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },

  async setInstagramAuthorize({commit}) {
    try {
      // var link = "https://api.instagram.com/oauth/authorize?client_id=361513778998142" +
      //   "&redirect_uri=https://socialsizzle.herokuapp.com/auth/" +
      //   "&scope=user_profile" +
      //   "&response_type=code" +
      //   "&state=1"

      const list = (await listaInstagram())
      console.log('setInstagramAuthorize: ',list)
      // console.log(JSON.parse(list))
      // var a = list.data

      // commit('SET_INSTAGRAM', list)
      // EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      // EventBus.$emit('LISTA_PROCESSADA', false)
    }
  }
}

const mutations = {
  SET_INSTAGRAM(state, instagram) {
    state.all = instagram
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
