/* eslint-disable */
import {API} from '../api'
import {acaoApi} from '../helpers/enums'

export const listaInstagram = async() => {

  const profileName = 'dr.guilhermebicalho'
  const baseUrl = 'https://www.instagram.com'
  const profileUrl = baseUrl + '/' + profileName
  const jsonDataUrl = encodeURI(profileUrl + '/?__a=1')
  var request = new Request(jsonDataUrl);
}

export const lista = (url) => {
  return API.get(url)
}

export const pegaLista = (url, data) => {
  return API.get(url, { params: {data: data} })
}

export const processar = (url, objeto, acao) => {
  if (acao === acaoApi.GRAVAR) {
    return API.post(url, objeto)
  } else if (acao === acaoApi.REMOVER) {
    return API.delete(url, {data: objeto})
  }
}
export const autentica = (url, objeto) => {
  return API.post(url, objeto)
}
export const pegaDados = (url, data) => {
  return API.post(url, data)
}

export const getAdress = (latlng) => {
  return API.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+latlng+'&key=AIzaSyAb0wzUYfv-8mxhDsIS8nv7ddodU-Df5Eo')
}
