<template>
  <v-card flat>
    <v-card-text>
      <div v-if="show_list">
          <v-row style="padding: 15px">
            <v-col v-for="(item, index) in listaDoencasVascualres" :key="'itens' + index" class="colunas_4 proboximagem" data-aos="fade-up">
              <box-imagem-doencas :item="item" @selecionado="getSelecionado"
                                  :src="selecionaImagem(item.urls_imagem, '200')"
                                  :titulo="item.titulo"></box-imagem-doencas>
            </v-col>
          </v-row>
      </div>
      <div v-else>
        <div class="text-center" style="padding-top: 10%">
          <v-progress-circular
            indeterminate
            :size="50"
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script >
/* eslint-disable */
  import {mapActions, mapGetters} from 'vuex'
  import miscMixin from '../../../helpers/misc'
  import router from '../../../router'
  import miscImageCropper from '../../../helpers/img-cropper'
  import {EventBus} from '../../../helpers/event-bus'

  export default {
    name: "DoencaVascular",
    mixins: [miscMixin,miscImageCropper],
    components: {
      BoxImagemDoencas: () => import('../../../components/BoxImagemDoencas.vue')
    },
    data: () => ({
      tab: null,
      show_list: false,
      cabecalho: [
        {text: 'Descricao', value: 'descricao'}
      ],
    }),
    methods: {
      ...mapActions('doenca', ['setAllDoencasVascular', 'searchItem']),
      getSelecionado(selecionado) {
        // sessionStorage.setItem('path_doenca', 'doenca_venosa')
        // sessionStorage.setItem('txt_doenca', 'Demais Patologias Vasculares')
        // sessionStorage.setItem('txt_outras', 'Doenças Venosas')
        // sessionStorage.setItem('doenca_detalhe', JSON.stringify(selecionado))
        // sessionStorage.setItem('lista_doencas', JSON.stringify(this.listaDoencasVascualres))
        router.push({name: 'doenca_detalhe', params: { doenca_id: selecionado.doenca_id } }).catch(err => {})
      }
    },

    created() {
      this.setAllDoencasVascular({
        onSucesso: () => {
          this.show_list = true
        },
        onErro: () => {
          this.show_list = true
        }
      })
    },

    mounted() {
    },
    computed: {
      ...mapGetters('doenca', {listaDoencasVascualres: 'listaDoencasVascualres'})
    },
    watch: {
      itemsPerPage: function () {
        this.setAllDoencas()
      }
    },
    // beforeDestroy() {
    //   EventBus.$off('LISTA_VASCULAR_PROCESSADA')
    // }
  }
</script>
