/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'aos/dist/aos.css'
import '../src/assets/css/main.scss'
import 'lightbox2/dist/css/lightbox.css'
import TablePageSimple from '../src/components/TablePageSimple.vue'
import ContainerForm from '../src/components/ContainerForm.vue'
import store from './store/index'
import {sync} from 'vuex-router-sync'
import * as VueGoogleMaps from 'vue2-google-maps'
import lightbox from 'lightbox2'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

Vue.use(lightbox)

// import ScrollAnimation from './assets/js/scrollanimation'
// Vue.directive('scrollanimation', ScrollAnimation);
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.component('pro-container-form', ContainerForm)
Vue.component('pro-table-simple', TablePageSimple)

Vue.use(Vuetify, { theme: {
  secondary: '#0a593b',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107'
} })

Vue.config.productionTip = false

sync(store, router)

// A key precisará ser alterada depois
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAb0wzUYfv-8mxhDsIS8nv7ddodU-Df5Eo',
    libraries: 'visualization'
  }
})

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi'
  }
}

new Vue({
  router,
  store: store,
  render: h => h(App),
  vuetify: new Vuetify(vuetifyOptions)
}).$mount('#app')
