/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from "./Dash";

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: Dash,
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "about" */ './views/Home/Home.vue')
        },
        {
          path: '/dr_guilherme',
          name: 'dr_guilherme',
          component: () => import(/* webpackChunkName: "about" */ './views/DrGuilherme/DrGuilherme.vue')
        },
        {
          path: '/doenca',
          name: 'doenca',
          component: () => import(/* webpackChunkName: "about" */ './views/Doencas/Doencas.vue')
        },
        {
          path: '/doenca_detalhe/:doenca_id',
          name: 'doenca_detalhe',
          component: () => import(/* webpackChunkName: "about" */ './views/Doencas/DoencaDetalhes.vue')
        },
        {
          path: '/doppler_vascular',
          name: 'doppler_vascular',
          component: () => import(/* webpackChunkName: "about" */ './views/DopperVasculares/DopperVasculares.vue')
        },
        {
          path: '/convenios',
          name: 'convenios',
          component: () => import(/* webpackChunkName: "about" */ './views/Convenios/Convenios.vue')
        },
        {
          path: '/blog',
          name: 'blog',
          component: () => import(/* webpackChunkName: "about" */ './views/Blog/Blog.vue')
        },
        {
          path: '/blog/:blog_id',
          name: 'Artigo',
          component: () => import(/* webpackChunkName: "about" */ './views/Blog/Artigo.vue')
        },

        {
          path: '/atendimento',
          name: 'Atendimento',
          component: () => import(/* webpackChunkName: "about" */ './views/Atendimento/Atendimento.vue')
        },
        {
          path: '/contato',
          name: 'Contato',
          component: () => import(/* webpackChunkName: "about" */ './views/Contato/Contato.vue')
        },
      ]
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "about" */ './views/404.vue')
    }
  ]
})
