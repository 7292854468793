/* eslint-disable */
import {lista, processar, pegaLista} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'

const url = 'contato'

const state = {
  all: [],
  
}


const getters = {
  listaAllPadrao: (state) => {
    return state.all
  }
}

const actions = {
  async processarContato({commit}, {contato, acao}) {
    try {
      let res = await processar(url, contato, acao)
      EventBus.$emit('CONTATO_PROCESSO_CONCLUIDO', true)
    } catch (error) {
      console.log('ERROR: ', error)
      EventBus.$emit('CONTATO_PROCESSO_CONCLUIDO', false)
    }
  },
}


const mutations = {
  SET_CONTATO(state, all) {
    state.all = all
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
