<template>
  <v-card flat>
    <v-card-text>
      <div v-if="show_list">
          <v-row style="padding: 15px">
            <v-col v-for="(item, index) in lista" :key="'itens' + index" class="colunas_4 proboximagem" data-aos="fade-up">
              <box-imagem-doencas :item="item" @selecionado="getSelecionado"
                                  :src="selecionaImagem(item.urls_imagem, '200')"
                                  :src2="selecionaImagem(item.urls_imagem, '50')"
                                  :titulo="item.titulo"></box-imagem-doencas>
            </v-col>
          </v-row>
      </div>
      <div v-else>
        <div class="text-center" style="padding-top: 10%">
          <v-progress-circular
            indeterminate
            :size="50"
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable */
  import {mapActions, mapGetters} from 'vuex'
  import miscMixin from '../../../helpers/misc'
  import router from '../../../router'
  import miscImageCropper from '../../../helpers/img-cropper'
  import {EventBus} from '../../../helpers/event-bus'
import {Doenca} from '../../../models/doenca'

  export default {
    name: "DoencaVenosa",
    mixins: [miscMixin, miscImageCropper],
    components: {
      BoxImagemDoencas: () => import('../../../components/BoxImagemDoencas.vue')
    },
    data: () => ({
      lista: [],
      show_list: false,
      cabecalho: [
        {text: 'Descricao', value: 'descricao'}
      ],
    }),
    methods: {
      ...mapActions('doenca', ['setAllDoencasVenosa','setDoenca']),
      getSelecionado(selecionado) {
        this.scroll_top()
        // sessionStorage.setItem('path_doenca', '/doenca_vascular')
        // sessionStorage.setItem('txt_doenca', 'Doenças Venosas')
        // sessionStorage.setItem('txt_outras', 'Demais Patologias Vasculares')
        // sessionStorage.setItem('doenca_detalhe', JSON.stringify(selecionado))
        // sessionStorage.setItem('lista_doencas', JSON.stringify(this.lista))
        router.push({name: 'doenca_detalhe', params: { doenca_id: selecionado.doenca_id } }).catch(err => {})
      }
    },

    created() {
      this.setDoenca(new Doenca())
      this.setAllDoencasVenosa()
    },

    mounted() {
      EventBus.$on('LISTA_VENOSA_PROCESSADA', (sucesso, lista) => {
        if (sucesso) {
          this.lista = lista
          this.show_list = true
        }
      })
    },
    computed: {
      ...mapGetters('doenca', {listaDoencasVenosas: 'listaDoencasVenosas'})
    },
    watch: {
      itemsPerPage: function () {
        this.setAllDoencasVenosa()
      }
    },
    beforeDestroy() {
      EventBus.$off('LISTA_VENOSA_PROCESSADA')
    }
  }

</script>

